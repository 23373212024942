<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">

    <Breadcrumbs style="display:inline-block;" />

    <div v-if="selectedCustomer" class="top-right-fab ma-2">
      <v-btn 
          :disabled="!canManageCustomers" class="techno-green" fab  v-on:click="confirmDelete()" :title="$t('general.delete')">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <br v-if="!selectedCustomer" />

    <v-container>
      <v-card class="border10" :disabled="!canManageCustomers">
        <v-progress-linear
          v-if="!selectedCustomer"
          indeterminate
        ></v-progress-linear>
        <v-skeleton-loader
          v-if="!selectedCustomer"
          type="table-heading, list-item-two-line, image, table-tfoot"
          style="margin: 25px;">
        </v-skeleton-loader>

        <v-container v-if="selectedCustomer" style="max-width:none;">
          <h3 class="mt-5 mb-5">{{ $t("customer.customer") + ' # ' + selectedCustomer.id }}</h3>
          <v-row>              
            <v-col lg="4" md="6" cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('reportInfo.firstname')"
                    :class="{ 'errored' : selectedCustomer.errorFirstname }"
                    dense
                    v-model="selectedCustomer.firstname"
                    maxlength="45"
                    hide-details
                    @change="onFirstNameChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('reportInfo.lastname')"
                    :class="{ 'errored' : selectedCustomer.errorLastname }"
                    dense
                    v-model="selectedCustomer.lastname"
                    maxlength="45"
                    hide-details
                    @change="onLastNameChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('reportInfo.company')"
                    :class="{ 'errored' : selectedCustomer.errorCompany }"
                    dense
                    v-model="selectedCustomer.company"
                    maxlength="255"
                    hide-details
                    @change="onCompanyChange()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-form ref="form" v-model="validEmails" lazy-validation>
                    <v-text-field v-model="selectedCustomer.emailMain" :rules="emailRules" :label="$t('user.main_email')" @change="emailMainChanged()"
                                  v-on:keydown.enter.prevent
                    ></v-text-field>
                  </v-form>   
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="selectedCustomer.phoneMobile" :label="$t('user.phone_mobile')" @change="phoneMobileChanged()"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="selectedCustomer.phoneOffice" :label="$t('user.phone_office')" @change="phoneOfficeChanged()"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select v-if="displayCustomerCategory"
                          dense
                          :items="[ { name: '', id: null }, 
                                    { name: $t('customer.home_owner'), id: CUSTOMER_CATEGORY.HOME_OWNER },
                                    { name: $t('customer.residential_company'), id: CUSTOMER_CATEGORY.RESIDENTIAL_COMPANY },
                                    { name: $t('customer.commercial_company'), id: CUSTOMER_CATEGORY.COMMERCIAL_COMPANY } ]"
                          :label="$t('customer.category')"
                          item-text="name"
                          item-value="id"
                          v-model="selectedCustomer.category" 
                          @change="categoryChanged();"></v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col lg="4" md="6" cols="12">
              <AddressField
              :address="selectedCustomer.address"
              @saveAddress="saveAddress"
            />
            </v-col>

            <v-col lg="4" md="12" cols="12">
              <div>
                <label for="dealers" style="display:block;">{{ $t('dealers.title') }}</label>
                <v-autocomplete
                  id="dealers"
                  dense
                  :items="dealers"
                  item-text="name"
                  return-object
                  v-model="selectedCustomer.editDealers"
                  multiple
                  chips
                  @change="dealerChanged()"
                ></v-autocomplete>
              </div>

              <div v-if="similarCustomers.length > 0 && !(similarCustomers.length == 1 && similarCustomers[0].id == selectedCustomer.id)">
                <label style="color:red">{{ $t('customer.similar_client_warning') }}</label>
                <v-btn tile color="#009f4d" style="color:white;margin-top:10px;display:block;" v-on:click="showSimilarCustomers">
                    {{ $t("customer.similar_client_list") }}
                  </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    
    <v-container>
      <v-card class="border10" v-if="selectedCustomer" :disabled="!canManageCustomers">
        <v-container>
          <h3>{{ $t("customer.contacts") }}</h3>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-data-table
                multi-sort
                dense
                v-model="selectedContact"
                :headers="contactColumns"
                :items="selectedCustomer.contacts"
                item-key="id"
                @click:row="v => selectedContact = [ v ]"
                :mobile-breakpoint="0" 
                :hide-default-footer="true"
                style="max-height: 300px;overflow: auto;"
              >
              </v-data-table>
              
              <v-btn
                tile
                color="#009f4d"
                class="white-text mt-2"
                @click="addContact()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t("general.add") }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="contact">
              <v-icon
                  class="delete-contact-btn"
                  @click="confirmDeleteContact()"
                >
                  mdi-close
              </v-icon>
              <v-row style="margin-top:30px;">
                <v-col cols="6">
                  <v-text-field
                    :label="$t('reportInfo.firstname')"
                    dense
                    v-model="contact.firstname"
                    maxlength="45"
                    :rules="[rules.required]"
                    @change="contactFirstnameChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('reportInfo.lastname')"
                    dense
                    v-model="contact.lastname"
                    maxlength="45"
                    @change="contactLastNameChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('user.phone_mobile')"
                    dense
                    v-model="contact.phoneMobile"
                    maxlength="45"
                    @change="contactPhoneMobileChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('users.email')"
                    dense
                    v-model="contact.email"
                    maxlength="45"
                    @change="contactEmailChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <!-- Confirm dialog generique -->
    <ConfirmDialog
      v-if="confirmDialog"
      v-model="confirmDialog"

      :title="confirmDialogTitle"
      :description="confirmDialogBody"

      :cancelName="confirmYesNo ? $t('reportInfo.no') : $t('confirm.cancel')"
      :cancelFunction="() => (confirmDialog = false)"
      :actionName="confirmYesNo ? $t('reportInfo.yes') : $t('confirm.ok')"
      :actionFunction="confirmDialogYes"
    />

    <!-- Confirm dialog si on tente de quitter la page actuelle avec des erreurs -->
    <ConfirmDialog
      v-if="confirmDialogRouteLeave"
      v-model="confirmDialogRouteLeave"

      :title="$t('confirm.head')"
      :description="$t('customer.fields_error_message')"

      :cancelName="$t('customer.cancel')"
      :cancelFunction="rejectRouteLeave"
      :actionName="$t('customer.yes')"
      :actionFunction="resolveRouteLeave"
    />

    <v-dialog v-if="similarCustomersDialog" v-model="similarCustomersDialog" max-width="1000">
      <v-card>
        <v-card-title>
          {{ $t('confirm.similar_client') }}
        </v-card-title>
        <v-card-text>{{ $t('confirm.similar_clients') }}</v-card-text>
        <v-card-actions>
          <v-simple-table style="width:100%;">
            <template v-slot:default>
              <tbody>
                <tr v-for="(similarCustomer, index) in similarCustomers" :key="index">
                  <td>{{ similarCustomer.formattedName + ' - ' + similarCustomer.formattedAddress }}</td>
                  <td>
                    <a :href="'/customer/' + similarCustomer.id" target="_blank"><v-icon style="float: right" class="mr-2">mdi-pencil</v-icon></a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="similarCustomersDialog = false;">{{ $t("general.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import AddressField from "@/components/reusableFields/AddressField";

import UserService from "@/services/user.service";
import CustomerService from "@/services/customer.service";

import { emailRules, capitalizeFirstLetter } from '@/utils/validation';
import { parseError, getLanguage } from "@/utils/utils"
import { getLongFormattedAddress, updateCustomerFormattedName } from "@/store/utility"
import { CUSTOMER_CATEGORY } from "@/enums";

const ConfirmAction = Object.freeze({ DeleteCustomer: 0, DeleteContact: 1 });
export default {
  components: {
    Breadcrumbs,
    ConfirmDialog,
    AddressField
  },
  computed: {
    ...mapGetters(['canManageCustomers']),
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      countries: (state) => state.countries
    }),
    contact() {
      if (this.selectedContact.length > 0) {
        return this.selectedContact[0];
      }
      return null;
    },
    displayCustomerCategory() {
      this.forceDisplayCustomerCategoryRecompute;
      for (let customerDealer of this.selectedCustomer.editDealers) {
        let dealer = this.dealers.find(d => d.id == customerDealer.id);
        if (dealer && dealer.displayCustomerCategory) {
          return true;
        }
      }
      return false;
    }
  },
  data() {
    return {
      CUSTOMER_CATEGORY: CUSTOMER_CATEGORY,
      autocomplete: null,
      dealers: [],
      confirmDialogRouteLeave: false,
      resolveRouteLeave: null,
      rejectRouteLeave: null,
      selectedCustomer: null,
      selectedCustomerId: null,
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      validEmails: true,
      emailRules: emailRules,
      areYouSureDialog: false,
      similarCustomers: [],
      similarCustomersDialog: false,
      selectedSimilarCustomerId: null,
      forceDisplayCustomerCategoryRecompute: 0,
      selectedContact: [],
      contactColumns: [
        {
          value: "firstname",
          text: this.$t('reportInfo.firstname'),
        },
        {
          value: "lastname",
          text: this.$t('reportInfo.lastname'),
        },
        {
          value: "phoneMobile",
          text: this.$t('user.phone_mobile'),
        },
        {
          value: "email",
          text: this.$t('users.email'),
        }
      ],
      rules: {
        required: value => !!value || this.$t("validation.required"),
      }
    };
  },
  watch: {
    
  },
  mounted() {
    console.log('Customer mounted')
    if (this.$refs && this.$refs.form) {
      this.$refs.form.resetValidation();
    }

    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("customers.customers"), href: "/customers" },
      {
        text: this.$t("customer.customer") + " # " + this.$route.params.id,
        href: "/customer/" + this.$route.params.id,
      },
    ]);

    if (this.selectedCustomer == null) {
      console.log('Setting selected customer id to ' + this.$route.params.id)
      this.selectedCustomerId = this.$route.params.id;
      this.retrieveCustomerData();
    }

    this.updateSimilarCustomers();
  },
  created: function() {
     console.log('Customer created')
  },
  beforeDestroy() {
    console.log('Customer unmounted')
  },
  beforeRouteLeave (to, from, next) {
			if(this.validEmails){
         next();
      } else {
        this.confirmDialogRouteLeave = true;

        new Promise((resolve, reject) => {
          this.resolveRouteLeave = resolve;
          this.rejectRouteLeave = reject;
        })
        .then((res) => {
          console.log(res);
          next();
        })
        .then((res) => { //eslint-disable-line
          this.confirmDialogRouteLeave = false;
        })
      }
	},
  methods: {
    ...mapMutations([
      'openToast',
      'showErrorMessage'
    ]),
    ...mapActions([ 
      'logout'
    ]),
    async deleteCustomer() {
      try {
        let response = await UserService.deleteCustomer(this.selectedCustomer.id);
        if (response.status == 200) {
          console.log(response)
          if (!response.data.hasSiteReport) {
            this.$router.push("/customers/");
          }
          else {
            this.openToast({ message: this.$t('confirm.used_client'), duration: 15000, color: "red" });
          }
        } else {
          this.openToast({ message: response, duration: 15000, color: "red" });
        }
      } catch (error) {
        console.log(error);
        this.openToast({ message: error, duration: 15000, color: "red" });
      }
    },

    confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.DeleteCustomer:
          this.deleteCustomer();
          break;
        case ConfirmAction.DeleteContact:
          this.deleteContact();
          break;
        default:
          break;
      }
      this.confirmDialog = false;
    },

    confirmDelete: function () {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t("confirm.head");
      this.confirmDialogBody = this.$t("confirm.delete_customer");
      this.confirmAction = ConfirmAction.DeleteCustomer;
      this.confirmYesNo = true;
    },

    async onFirstNameChange() {
      this.selectedCustomer.firstname = capitalizeFirstLetter(this.selectedCustomer.firstname);
      this.selectedCustomer.changes.firstname = this.selectedCustomer.firstname;
      await this.saveChanges();

      this.updateSimilarCustomers();
    },
    async onLastNameChange() {
      this.selectedCustomer.lastname = capitalizeFirstLetter(this.selectedCustomer.lastname);
      this.selectedCustomer.changes.lastname = this.selectedCustomer.lastname;
      await this.saveChanges();

      this.updateSimilarCustomers();
    },
    async onCompanyChange() {
      this.selectedCustomer.company = capitalizeFirstLetter(this.selectedCustomer.company);
      this.selectedCustomer.changes.company = this.selectedCustomer.company;
      await this.saveChanges();

      this.updateSimilarCustomers();
    },
    emailMainChanged() {
      if(this.validEmails){
         this.selectedCustomer.changes.emailMain = this.selectedCustomer.emailMain;
         this.saveChanges();
      }
    },
    phoneMobileChanged() {
      this.selectedCustomer.changes.phoneMobile = this.selectedCustomer.phoneMobile;
      this.saveChanges();
    },
    phoneOfficeChanged() {
      this.selectedCustomer.changes.phoneOffice = this.selectedCustomer.phoneOffice;
      this.saveChanges();
    },
    categoryChanged() {
      this.selectedCustomer.changes.category = this.selectedCustomer.category;
      this.saveChanges();
    },
    dealerChanged() {
      this.selectedCustomer.changes.addedDealers = [];
      this.selectedCustomer.changes.deletedDealers = [];

      this.forceDisplayCustomerCategoryRecompute++;

      this.selectedCustomer.editDealers.forEach(ed => {
        let isPresent = false;
        for (let i = 0; i < this.selectedCustomer.dealers.length; i++) {
          if (this.selectedCustomer.dealers[i].id == ed.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedCustomer.changes.addedDealers.push(ed.id);
        }
      });

      this.selectedCustomer.dealers.forEach(d => {
        let isPresent = false;
        for (let i = 0; i < this.selectedCustomer.editDealers.length; i++) {
          if (this.selectedCustomer.editDealers[i].id == d.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          this.selectedCustomer.changes.deletedDealers.push(d.id);
        }
      });

      this.saveChanges();
      this.selectedCustomer.dealers = this.selectedCustomer.editDealers;
    },

    async saveAddress(addressChanges) {
      this.selectedCustomer.changes = {...this.selectedCustomer.changes, ...addressChanges};
      await this.saveChanges();

      this.updateSimilarCustomers();
    },

    showSimilarCustomers() {
      let clientList = [];
      for (let i = 0; i < this.similarCustomers.length; i++) {
        if (this.similarCustomers[i].id != this.selectedCustomer.id) {
          clientList.push(this.similarCustomers[i]);
        }
      }
      this.similarCustomers = clientList;
      this.similarCustomersDialog = true;
      this.selectedSimilarCustomerId = this.similarCustomers[0].id;
    },

    updateSimilarCustomers() {
      
      var scope = this;
      CustomerService.getSimilarCustomers(this.selectedCustomerId)
      .then(function (similarCustomers) {
        console.log('getSimilarCustomers', similarCustomers)
      
        scope.similarCustomers = similarCustomers;
      })
      .catch(function (error) {
        scope.showErrorMessage(parseError(error));
      })
    },

    async saveChanges() {
      await this.saveCustomerChanges();
    },
    retrieveCustomerData() {
      console.log('Retrieving customer data...')

      var scope = this;
      UserService.getCustomerPageData(scope.selectedCustomerId, getLanguage())
      .then(function (response) {
        console.log('getCustomerPageData', response.data)

        scope.setSelectedCustomer(response.data.customer);
        scope.dealers = response.data.dealers;
      })
      .catch(function (error) {
        // handle error
        scope.showErrorMessage(parseError(error));

        if (error.toString().includes('401')) {
          scope.logout(this.$socket);
        }
        else if (error.toString().includes('500')) {
          console.log('Redirecting to customers from store...')
          scope.$router.push('/customers/');
        }
      })
    },
    setSelectedCustomer(customer) {
      console.log('setSelectedCustomer', customer)
      this.selectedCustomer = customer;

      if (customer) {
        this.resetCustomerChanges(customer);
        customer.errors = [];
  
        this.formatCustomer(customer);
      }
    },
    async saveCustomerChanges() {
      console.log(this.selectedCustomer.changes);

      let response = await UserService.saveCustomerChanges(this.selectedCustomer);
      console.log(response);
      if (response.status == 200) {
        this.resetCustomerChanges(this.selectedCustomer);

        this.updateStoreCustomer(this.selectedCustomer);
      }
      else {
        this.showErrorMessage(parseError(response));
      }
    },
    resetCustomerChanges(customer) {
      customer.changes = { addedDealers: [], deletedDealers: [] };
    },
    formatCustomer(customer) {
      if (customer.address.countryId) {
        customer.address.country = this.countries.find(c => c.id == customer.address.countryId);
      }
    
      customer.editDealers = JSON.parse(JSON.stringify(customer.dealers, null, 2));
    },
    updateStoreCustomer(customer) {
      updateCustomerFormattedName(customer);
      customer.formattedAddress = getLongFormattedAddress(this.countries, customer.address);
    },
    getContactChanges() {
      if (!this.contact.changes) {
        this.contact.changes = {};
      }
      return this.contact.changes;
    },

    async contactFirstnameChange() {
      if (!this.contact.firstname || this.contact.firstname.length == 0) {
        return;
      }
      this.contact.firstname = capitalizeFirstLetter(this.contact.firstname);
      this.getContactChanges().firstname = this.contact.firstname;
      await this.saveContactChanges();
    },
    async contactLastNameChange() {
      this.contact.lastname = capitalizeFirstLetter(this.contact.lastname);
      this.getContactChanges().lastname = this.contact.lastname;
      await this.saveContactChanges();
    },
    async contactPhoneMobileChange() {
      this.getContactChanges().phoneMobile = this.contact.phoneMobile;
      await this.saveContactChanges();
    },
    async contactEmailChange() {
      this.getContactChanges().email = this.contact.email;
      await this.saveContactChanges();
    },
    async saveContactChanges() {

      let changes = this.contact.changes;
      this.contact.changes = {};

      let response = await CustomerService.saveContactChanges(this.selectedCustomer.id, this.contact.id, changes);
      console.log(response);
      if (response.status != 200) {
        this.showErrorMessage(parseError(response));
      }
    },
    async addContact() {
      console.log('addContact')
      
      let response = await CustomerService.saveContactChanges(this.selectedCustomer.id, -1, {  });
      console.log(response);
      if (response.status == 200) {
        if (response.data.contact) {
          let contact = response.data.contact;
          this.selectedCustomer.contacts.push(contact);

          this.selectedContact = [ contact ];
        }
      }
      else {
        this.showErrorMessage(parseError(response));
      }
    },
    confirmDeleteContact() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t("confirm.head");
      this.confirmDialogBody = this.$t("customer.delete_contact");
      this.confirmAction = ConfirmAction.DeleteContact;
      this.confirmYesNo = true;
    },
    async deleteContact() {
      let response = await CustomerService.deleteContact(this.selectedCustomer.id, this.contact.id);
      if (response.status == 200) {
        console.log(response)
        if (!response.data.hasSiteReport) {

          let contactIndex = this.selectedCustomer.contacts.findIndex(c => c.id == this.contact.id);
          if (contactIndex >= 0) {
            this.selectedCustomer.contacts.splice(contactIndex, 1);
          }
          this.selectedContact = [];
          this.openToast({ message: this.$t('customer.contact_deleted'), duration: 15000, color: "green" });
        }
        else {
          this.openToast({ message: this.$t('customer.contact_in_use'), duration: 15000, color: "red" });
        }
      } 
      else {
        this.openToast({ message: response, duration: 15000, color: "red" });
      }
    },
  }
};
</script>

<style scoped>
label {
  color: rgba(0, 0, 0, 0.6);
}
.alternate {
  background: #e8e4e47a;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 4px;
}

.v-text-field.v-text-field--solo.v-input--dense > ::v-deep.v-input__control {
  min-height: 32px!important;
}

.delete-contact-btn {
  position: absolute;
  top: 3px;
  right: 0px;
  margin: 16px;
}
</style>